<template>
    <div class="modal-background fade-in" @click.self="$emit('on:closeRe')">
        <div class="modal-container">
            <div class="img-scroll">
                <img v-bind:src="userRe" class="img-thumbnail" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {

    emits: ['on:closeRe'],

    props: {
        userRe: {
            type: String
        }
    },

    setup(props) {

        const userAnProps = ref();
        userAnProps.value = props.userAn

        return {
            userAnProps
        }

    }

}
</script>

<style lang="scss" scoped>
img {
    border: 4px solid white;
}
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
}

.modal-container {
    color: white;
    width: auto;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.img-scroll {
    height: auto;
    max-height: 600px;
    width: auto;
    overflow: auto;
}

.fade-in {
    animation: fadeIn ease-out 0.2s;
    -webkit-animation: fadeIn ease-out 0.2s;
    -moz-animation: fadeIn ease-out 0.2s;
    -o-animation: fadeIn ease-out 0.2s;
    -ms-animation: fadeIn ease-out 0.2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>